<script setup lang="ts">
import useCpBem from '~/composables/useCpBem';
import CpProductCard from '~/components/cp/CpProductCard/CpProductCard.vue';

    defineProps<{
      kind: 'FeaturedOffer'|'Standard'|'Offer'|'Featured'|'Oos'|'OosOffer',
      productType: 'physical'|'esd',
      pillLarge: boolean,
      pillShort: boolean,
      rate: boolean,
      rateValue: number | string,
      reviews: number | string,
      freeShipping: boolean,
      link: string,
      title: string,
      hasDiscount: boolean,
      discount: string | boolean,
      picture: string,
      imageTitle: string,
      price: number,
      tPrice: number,
      id: string,
    }>();

    const {b} = useCpBem('cp-product-card-home');
</script>
<template>
    <div :class="b">
        <CpProductCard
            :id="id"
            data-testid="product-card-home"
            :kind="kind"
            :product-type="productType"
            :pill-large="pillLarge"
            :pill-short="pillShort"
            :rate="rate"
            :rate-value="Number(rateValue)"
            :reviews="Number(reviews)"
            :free-shipping="freeShipping"
            :link="link"
            :title="title"
            :has-discount="hasDiscount"
            :discount="discount"
            :picture="picture"
            :image-title="imageTitle"
            :price="price"
            :t-price="tPrice"
        />
    </div>
</template>

<style scoped lang="scss">
  @import "assets/style/global.scss";
  .cp-product-card-home {
    border: $cp-border-standard;
    border-radius: $cp-gutter-small;
    width: calc(50% - $cp-gutter-small);
    height: 300px;
  }
</style>
